import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastDefaultProperty = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
};

export const successToast = (message) => {
    toast.success(message, toastDefaultProperty);
};

export const errorToast = (message) => {
    toast.error(message, toastDefaultProperty);
};

export const infoToast = (message) => {
    toast.info(message, toastDefaultProperty);
};

export const warnToast = (message) => {
    toast.warn(message, toastDefaultProperty);
};

export const defaultToast = (message) => {
    toast(message, toastDefaultProperty);
};

export const ToastMsg = () => {
    return (
        <ToastContainer position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
    );
}