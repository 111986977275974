import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import Admin from './reducer/admin.reducer.js';
import Sidebar from './reducer/sidebar.reducer.js';
import User from './reducer/user.reducer.js';
import Products from './reducer/product.reducer.js';
import MyProfile from './reducer/myProfile.reducer.js';

const AppReducers = combineReducers({
  Admin, Sidebar, User, Products, MyProfile
});

const rootReducer = (state, action) => {
	return AppReducers(state, action);
}

let store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;