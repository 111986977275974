const initialState = {
    profile_image: false,
    name:"",
    self_id: "",
    user_type: "",
    user_permissions: {},
}
  
const MyProfile = (state = initialState, action) => {
    if(state === null || state === undefined || action === null){
        return { ...state }
    }
    switch (action.type) {
        case 'MY_PROFILE':
            return {...state, ...action.payload }
        default:
        return state;
    }
}

export default MyProfile;