import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastMsg } from './Component/Toast/Toast.js';
import './scss/style.scss';
import './Assets/css/style.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/Auth/login/Login'));
const ForgetPassword = React.lazy(() => import('./views/Auth/forget-password/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./views/Auth/reset-password/ResetPassword'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/forget-password" name="Forget Password" render={props => <ForgetPassword {...props}/>} />
            <Route path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props}/>} />
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
          </Switch>
        </React.Suspense>
        <ToastMsg/>
      </BrowserRouter>
    );
  }
}

export default App;
